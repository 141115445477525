/*
 * @Author: minihorse
 * @Date: 2020-12-09 15:30:00
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-03-24 10:00:40
 * 
 */
const Layout = () => import('../pages/layout')
// const Mobile = () => import('../pages/layout/mindex')
const staticRoutes = [
    {
        path: '/',
        redirect: '/login'
    },    
    {
        path: '/login',
        component: () => import(/* webpackChunkName:'second' */ '../pages/login/index.vue')
    },
    // {
    //     path: '/menu',
    //     component: Layout,
    //     children: [
    //         {
    //             path: 'list',
    //             meta: {
    //                 title: '菜单列表'
    //             },
    //             component: () => import(/* webpackChunkName:'second' */ '../pages/menu/list')
    //         },
    //     ]
    // },
    {
        path: '/datum',
        component: Layout,
        children: [
            {
                path: 'list',
                meta: {
                    title: '信息列表'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/datum/list')
            },
            {
                path: 'list/:status',
                meta: {
                    title: '推广中'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/datum/list')
            },
            {
                path: 'new/:id',
                meta: {
                    title: '修改信息'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/datum/new')
            },
            {
                path: 'isvnew/:id',
                meta: {
                    title: '修改信息'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/datum/isvnew')
            },
            {
                path: 'huahui/:id',
                meta: {
                    title: '修改花卉信息'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/datum/huahui')
            },
            {
                path: 'isvhuahui/:id',
                meta: {
                    title: '修改花卉信息'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/datum/isvhuahui')
            },
            {
                path: 'weihua/:id',
                meta: {
                    title: '修改危化品信息'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/datum/weihua')
            },
            {
                path: 'isvweihua/:id',
                meta: {
                    title: '修改危化品信息'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/datum/isvweihua')
            },
            {
                path: 'new',
                meta: {
                    title: '新建信息'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/datum/new')
            },
            {
                path: 'isvnew',
                meta: {
                    title: '修改信息'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/datum/isvnew')
            },
            {
                path: 'huahui',
                meta: {
                    title: '新建花卉信息'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/datum/huahui')
            },
            {
                path: 'weihua',
                meta: {
                    title: '新建危化品信息'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/datum/weihua')
            },
            {
                path: 'isvhuahui',
                meta: {
                    title: '新建花卉信息'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/datum/isvhuahui')
            },
            {
                path: 'isvweihua',
                meta: {
                    title: '新建危化品信息'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/datum/isvweihua')
            },
            {
                path: 'goodslist/:id',
                meta: {
                    title: '商品信息'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/datum/goodslist')
            },
        ],
    },
    {
        path: '/picture',
        component: Layout,
        children: [
            {
                path: 'create',
                meta: {
                    title: '图片生成'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/picture/create')
            },
            {
                path: 'solarterm',
                meta: {
                    title: '节气图片'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/picture/solarterm')
            },
        ],
    },
    /* 本段代码放在最后，未设置路由的地址跳转404 */
    {
        path:'*',
        redirect: '/404'
    },
    {
        path: '/404',
        component: Layout,
        children: [
            {
                path: '',
                meta: {
                    title: '页面不存在'
                },
                component: () => import(/* webpackChunkName:'second' */ '../pages/404')
            },
        ]
    },
    /** 本段代码放在最后，未设置路由的地址跳转404 */
]
export default staticRoutes